import React, { ReactNode, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useToast from "./use-toast"
import { ToastTypes } from "../ui/toast/toast"
import ConfirmationModal from "../ui/confirmation-modal/confirmation-modal"
import { useStores } from "pitch45-common/stores/root-store-context"
import { Group } from "pitch45-common/models/group"
import { fetchGroupsForAdminQueryKey } from "pitch45-common/utils/query-keys"

interface DeleteGroupHook {
  /**
   * Is there an async process in progress related to deleting a group?
   */
  isLoading: boolean

  /**
   * Initiate the group deletion process
   * @returns
   */
  onDeleteGroup: () => void

  /**
   * Component to render to enable group deletion workflow
   * @returns
   */
  renderConfirmation: () => ReactNode
}

export const useDeleteGroup = (
  group: Group,
  beforeDelete: () => Promise<any>,
  afterDelete: () => Promise<any>,
): DeleteGroupHook => {
  const { groupStore } = useStores()
  const [open, setOpen] = useState(false)
  const toast = useToast()
  const queryClient = useQueryClient()

  const deleteMutation = useMutation(
    async () => {
      await beforeDelete()
      if (group) {
        return groupStore.deleteGroup(group)
      }
    },
    {
      onError: () => {
        toast.show("Unable to delete group", { type: ToastTypes.Error })
      },
      onSuccess: () => {
        if (group) {
          queryClient.invalidateQueries(fetchGroupsForAdminQueryKey(group.organizationId))
        }
        afterDelete()
      },
    },
  )

  return {
    isLoading: deleteMutation.isLoading,
    onDeleteGroup: async () => {
      setOpen(true)
    },
    renderConfirmation: () => {
      return (
        <ConfirmationModal
          open={open}
          message={"Please confirm you want to delete this Group"}
          loading={deleteMutation.isLoading}
          onConfirm={deleteMutation.mutateAsync}
          confirmationText={"Delete"}
          onDecline={() => setOpen(false)}
        />
      )
    },
  }
}
